export default function getLastPredictionDate(
  domainsMetadata,
  currentSelectedRetailer,
  productAttributes
) {
  const getLastPrediction = (retailer) =>
    new Date(domainsMetadata[retailer].default.default.lastPredictionDate);

  let lastPrediction = null;

  if (currentSelectedRetailer && domainsMetadata) {
    if (Array.isArray(currentSelectedRetailer)) {
      lastPrediction = new Date(
        Math.min.apply(null, currentSelectedRetailer.map(getLastPrediction))
      );
    } else lastPrediction = getLastPrediction(currentSelectedRetailer);

    // check if a selected product attribute has specific last prediction date
    if (
      productAttributes &&
      ((Array.isArray(currentSelectedRetailer) &&
        currentSelectedRetailer.length === 1) ||
        !Array.isArray(currentSelectedRetailer))
    ) {
      for (var productAttribute in productAttributes) {
        let name = productAttributes[productAttribute].mbName;
        if (productAttributes[productAttribute].values?.length == 1) {
          let value = productAttributes[productAttribute].values[0];
          if (
            (domainsMetadata[currentSelectedRetailer][name] ?? {})[value]
              ?.lastPredictionDate
          ) {
            lastPrediction = new Date(
              domainsMetadata[currentSelectedRetailer][name][
                value
              ].lastPredictionDate
            );
            break;
          }
        }
      }
    }
  }

  return lastPrediction;
}
